import React, { useState, useEffect, useContext, createContext } from 'react';

interface User {
  firstname: string;
  lastname: string;
  email: string;
  LoginToken: string;
}

interface IAuthContext {
  user: User | null | undefined;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
});

export const useAuth = (): IAuthContext => {
  return useContext(AuthContext);
};

export default function AuthProvider({ children }: any): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loginUser: any = localStorage.getItem('LoginToken');
    console.log(loginUser);

    setIsLoading(true);

    if (loginUser) {
      // Parse the stored user data (assuming it's in JSON format)
      const parsedUser = loginUser;
      setUser(parsedUser);
    }

    setIsLoading(false);
  }, []);

  return isLoading ? (
    <></>
  ) : (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}
