import React from 'react';
import Routes from "./Component/routes";

import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './provider/authProvider';


const App: React.FC = () => {
  return (
    <AuthProvider>
    <div className="App">
       <BrowserRouter>
      <Routes/>
      </BrowserRouter>     
    </div>
    </AuthProvider>
  );
};

export default App;
