// src/services/Auth.ts

import {
  LoginPayload,
  NewUserAccountPayload,
  LoginsPayload,
  ForgotPasswordPayload,
  UpdateItemPayload,
  ChangePasswordPayload,
  listOfUsers,
  getListOfUsers,
  InviteSomeone,
  DeleteApplication,
  ApplicationsAccess,
  getApplicationsAccess,
  AcceptInviteApplication,
  DeclineInviteApplication,
  Logout,
} from '../services/authType';
import axiosInstance from '../config/apiconfig';

const Auth = {
  getAccessToken() {
    return localStorage.getItem('LoginToken');
  },

  isAuthenticated(): boolean {
    return this.getAccessToken() ? true : false;
  },

  async signInWithEmailPassword(payload: LoginPayload) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/~LoginNow`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async registerNewUser(payload: NewUserAccountPayload) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/~RegisterUserPasswordGenerate`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },
  async newUserAccount(payload: { email: string }) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/~LoginAvailable`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async newUsernmaeAccount(payload: { username: string }) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/~UsernameAvailable`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async forgotPassword(payload: ForgotPasswordPayload) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/~ForgotPassword`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async updateItem(payload: UpdateItemPayload) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/~UpdateItem`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async changePassword(payload: ChangePasswordPayload) {
    try {
      const id = localStorage.getItem("LoginID")
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${id}/~ChangePassword`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async getMyInfo(payload: LoginsPayload) {
    const id = localStorage.getItem("LoginID")
    try {
      const response = await axiosInstance.post(`Domains/ID_2/Logins/ID_${id}`, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  
  async listOfUsers(value:any) {
    try {
      const response = await axiosInstance.post(`Domains/ID_2/~LoginMatch`,
      {
        value:value 
      });
      return response.data;
    } catch (error) { 
      // Handle the error here
      throw error;
    }
  },

  async listOfApps() {
    try {
      const response = await axiosInstance.post(`/ApplicationTypes`);
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async getListOfUsers(id: number): Promise<getListOfUsers[]> {
    try {
      const response = await axiosInstance.get(`Domains/ID_2/Logins/${id}`);
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async inviteSomeone( payload: InviteSomeone) {
    try {
      const id = localStorage.getItem('LoginID')
      const response = await axiosInstance.post(`Domains/ID_2/Logins/ID_${id}/Applications_Own/ID_${payload.applicationID}/~InviteSomeoneToThisApp`,{
        ID:payload.ID 
      }
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async deleteApplication(payload: DeleteApplication) {
    try {
      const userID = localStorage.getItem('LoginID')
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userID}/~DeleteApplication`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  //----------------Application i have acess--------------------

  async ApplicationsAccess() {
    try {
      const userId = localStorage.getItem('LoginID');
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userId}/ApplicationsAccess`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async getAllApplicationsAccess(id: number): Promise<getApplicationsAccess[]> {
    try {
      const response = await axiosInstance.get(
        `Domains/ID_2/Logins/ID_14/ApplicationsAccess/${id}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async AcceptInvite(payload: AcceptInviteApplication) {
    try {
      const userId = localStorage.getItem('LoginID');
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userId}/ApplicationsAccess/ID_${payload.applicationID}/~AcceptInvite`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },
  async DeclineInvite(payload: DeclineInviteApplication) {
    try {
      const userId = localStorage.getItem('LoginID');
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userId}/ApplicationsAccess/~DeleteItem`,
      payload);
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async Logout(payload: Logout) {
    try {
      const response = await axiosInstance.post(
        `Domains/ID_2/~LogoutNow`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  logOut() {
    localStorage.clear();
    window.location.href = '/';
    window.location.reload();
  },
};

export default Auth;
