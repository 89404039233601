
import React from 'react'
import { Outlet } from 'react-router-dom'

const MinimalLayout:React.FC = () => {

  return (
    <div>
        
   <Outlet/>
    </div>
  )
}

export default MinimalLayout