import { lazy } from 'react';
// project import
import MainLayout from "../layout/mainLayout";
import React from 'react';
import Dashboard from '../dashboard/dashboard';
import Profile from "../dashboard/profile";
import Launch from '../dashboard/Launch';




// render - utilities

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/dashboard',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'Launch',
      element: <Launch />
    }
   
    
   
    

  ]
};

export default MainRoutes;
