import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';\
// import { Link } from 'react-router-dom';
import Link from '@mui/material/Link';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Auth from '../../services/authService';

const pages = ['Dashboard'];

const Header: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userEmail: any = localStorage.getItem('email');
  const avatar = userEmail?.charAt(0) || 'A';
  const handleLogout = async () => {
    try {
      const loginID = localStorage.getItem('LoginID');
      const ID = loginID ? parseInt(loginID) : 0; // Assuming 0 as a default value when ID is not available

      const payload = { ID }; // Wrap the ID in an object as required by your API

      const response = await Auth.Logout(payload); // Call the Logout function with the payload

      navigate('/');
      console.log(response); // Log the response from the API
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleProfile = () => {
    window.location.href = '/dashboard/profile';
  };

  return (
    <>
      <AppBar position="static" className="header_section">
        <Container maxWidth="xl" className="">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <img
              srcSet={`/Images/logo.png`}
              src={`/Images/logo.png`}
              width="60px"
              className="Dashborad_logo_img"
              onClick={() => (window.location.href = '/dashboard')}
            />

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                className=""
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page}
                    onClick={() => (window.location.href = '/dashboard')}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <img
              srcSet={`/Images/logo.png`}
              src={`/Images/logo.png`}
              width="60px"
              className="Dashborad_logo_img_moblie"
            />
            <Box
              sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
              className="Dashborad_menu_area_Desktop"
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => (window.location.href = '/dashboard')}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={avatar.toUpperCase()}
                    src={avatar.toUpperCase()}
                  />
                  {/* <span>{avatar.toUpperCase()}</span> */}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <button style={{ background: 'none', border: 'none' }}>
                    <Link
                      variant="body2"
                      sx={{ color: '#252525' }}
                      onClick={handleProfile}
                    >
                      {'Profile'}
                    </Link>
                  </button>
                </MenuItem>

                <MenuItem>
                  <button
                    style={{ background: 'none', border: 'none' }}
                    onClick={handleLogout}
                  >
                    <Link variant="body2" sx={{ color: '#252525' }}>
                      {'Logout'}
                    </Link>
                  </button>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
