import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import Link from '@mui/material/Link';
import { FormControl, Grid } from '@mui/material';
import { Form } from 'react-router-dom';

import TextField from '@mui/material/TextField';

import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangePasswordPayload } from '../../services/authType';
import Auth from '../../services/authService';
import Swal from 'sweetalert2';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Backdrop, CircularProgress } from '@mui/material';

import { UpdateItemPayload } from '../../services/authType';

import { useAuth } from '../../provider/authProvider';
import { LoginsPayload } from '../../services/authType';

const pages = ['Dashboard'];

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Profile: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [loading, setLoading] = React.useState(false);
  const { user } = useAuth();
  // const[userdata,setUserdata] = React.useState<any>({})

  // console.log(userdata,"-----userdata=========================================")

  const validationSchemaProfile = Yup.object().shape({
    // email: yup.string().required('Email is required').email('Email is invalid'),
    firstName: Yup.string()
      .required('First name is required')
      .min(3, 'firstname must be at least 6 characters')
      .max(32, 'Password cannot be longer than 32 characters')
      .trim(),
    lastName: Yup.string()
      .required('Last name is required')
      .min(3, 'lastname must be at least 6 characters')
      .max(32, 'Password cannot be longer than 32 characters')
      .trim(),
  });

  const {
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: pError },
    reset: resetP,
    control,
  } = useForm<any>({
    resolver: yupResolver<any>(validationSchemaProfile),
  });

  const profileErrors: any = pError;

  const onSubmitProfile = async (dataprofile: UpdateItemPayload) => {
    const loginID = localStorage.getItem('LoginID');
    const idNumber = loginID ? parseInt(loginID, 10) : 0; // Assuming 0 as a default value when ID is not available

    const payloadProfile = {
      firstname: dataprofile.firstname,
      lastname: dataprofile.lastname,
      ID: idNumber,
    };
    await HandleAccount(payloadProfile);
  };

  const HandleAccount = async (payloadProfile: UpdateItemPayload) => {
    try {
      setLoading(true);
      const response = await Auth.updateItem(payloadProfile);
      console.log(response);

      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred while updating. Please try again later.',
      });
      setLoading(false);
    }
  };

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [userName, setUserName] = React.useState('');


  const getUser = async (payload: LoginsPayload) => {
    try {
      setLoading(true);
      const response = await Auth.getMyInfo(payload); //Pass the ID parameter to your API call
      const data = response;

      if (data) {
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setUserName(data.username);

        resetP(data);
        // Save first name and last name in localStorage
        localStorage.setItem('firstName', data.firstname);
        localStorage.setItem('lastName', data.lastname);
        localStorage.setItem('userName', data.username);

        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const userFirstName = localStorage.getItem('firstName');
  const userLastName = localStorage.getItem('lastName');

  React.useEffect(() => {
    // console.log(userdata); // Log userdata to the console
    const loginID = localStorage.getItem('LoginID');
    const ID = loginID ? parseInt(loginID, 10) : 0; // Assuming 0 as a default value when ID is not available

    const payload = { ID }; // Wrap the ID in an object as LoginsPayload expects an object with ID property

    getUser(payload); // Pass the payload to getUser
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string()
      .required('Old Password is required')
      .min(6, 'Password must be at least 8 characters long'),
      // .max(32, 'Password cannot be longer than 32 characters'),
    newpassword: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters long')
      // .max(32, 'Password cannot be longer than 32 characters')
      // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      // .matches(/[0-9]/, 'Password must contain at least one number')
      // .matches(
      //   /[!@#$%^&*]/,
      //   'Password must contain at least one special character',
      // )
      .matches(/^\S*$/, 'Password cannot contain spaces'),

    confirmpassword: Yup.string()
      .oneOf([Yup.ref('newpassword')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordPayload>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const payload = {
      oldpassword: data.oldpassword,
      newpassword: data.newpassword,
    };
    await HandleChange(payload);
  };

  const [message, setMessage] = React.useState('');

  const HandleChange = async (payload: any) => {
    try {
      const response = await Auth.changePassword(payload);
      setMessage('Password changed successfully.');
      setTimeout(() => {
        setMessage('');
      }, 3000); // Adjust the duration as needed (in milliseconds)

      reset();
      console.log(response);
      // Handle success response here (e.g., show a success message)
    } catch (error) {
      console.error('Error Changing Password in:', error);
      setMessage('Old password does not match. or server issue');
      setTimeout(() => {
        setMessage('');
      }, 3000);
      reset();
    }
  };

  const userDetail = localStorage.getItem('email');




  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      </Box>

      <Box component="section" className="Profile_Banner_section"></Box>
      <Box component="section" className="Profile_content_section">
        <Container maxWidth="xl" className="">
          <Box component="div" className="Profile_content_area">
            <Avatar
              alt="Olivia"
              src={`/Images/logo.png`}
              className="Profile_content_img"
            />
            <Box component="div" className="Profile_content_Detail">
              <Typography component="h5" variant="h5">
                {userFirstName} {userLastName}
              </Typography>
              <Typography component="p" variant="h3">
                {userDetail}
              </Typography>    
            </Box>
          </Box>
        </Container>
      </Box>

      <Box component="section" className="Profile_update_from_section">
        <Container maxWidth="xl">
          <Grid container>
            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={4}
              xs={12}
              className="Profile_update_tabs_area"
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab
                  label="Profile Update"
                  className="Profile_Update_tabs_button"
                  {...a11yProps(0)}
                />
                <Tab
                  label="Change Password"
                  className="Profile_Update_tabs_button"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
            <Grid
              item
              xl={5}
              lg={5}
              md={7}
              sm={8}
              xs={12}
              className="Profile_tabsContent_from_Area"
            >
              <TabPanel value={value} index={0}>
                <Box className="Profile_changePassword_from_Area">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="Profile_update_from_heading"
                  >
                    Profile Update
                  </Typography>
                  <form onSubmit={handleSubmitProfile(onSubmitProfile)}>
                    <TextField
                      margin="normal"
                      fullWidth
                      value={userDetail}
                      label="Email Name"
                      type="Email"
                      id="Email"
                      //  mailto:defaultvalue="oliviajain@gmail.com"
                      autoComplete="current-Email"
                      sx={{ mt: 2 }}
                      //disabled
                      InputProps={{ readOnly: true }}
                     
                    />


                     <TextField
                      margin="normal"
                      fullWidth
                      value={userName}
                      label="Username"
                      type="Text"
                      id="UserName"
                    
                      autoComplete="current-UserName"
                      sx={{ mt: 2 }}
                     // disabled
                     InputProps={{ readOnly: true }}
                    />

                    <Controller
                      control={control}
                      name="fullName"
                      defaultValue={userDetail}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          // value={userdata?.firstname || ''}
                          //    defaultValue="Olivia"
                          value={firstName}
                          autoComplete="FirstName"
                          sx={{ mt: 2 }}
                          type="text"
                          autoFocus
                          {...registerProfile('firstName', {
                            onChange: (e) => {
                              setFirstName(e.target.value);
                            },
                          })}
                        />
                      )}
                    />
                    {profileErrors && (
                      <p style={{ color: 'red' }}>
                        {profileErrors?.firstName?.message}
                      </p>
                    )}

                    <Controller
                      control={control}
                      name="fullName"
                      defaultValue={userDetail}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          // value={userdata?.lastname || ''}
                          value={lastName}
                          label="Last Name"
                          type="text"
                          //    defaultValue="Jain"
                          id="lastName"
                          autoComplete="current-LastName"
                          sx={{ mt: 2 }}
                          {...registerProfile('lastName', {
                            onChange: (e) => {
                              setLastName(e.target.value);
                            },
                          })}
                        />
                      )}
                    />
                    <p style={{ color: 'red' }}>
                      {profileErrors.lastName?.message}
                    </p>

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mb: 1, mt: 3, fontWeight: '700' }}
                    >
                      Update Profile
                    </Button>
                  </form>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box className="Profile_changePassword_from_Area">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="Profile_update_from_heading"
                  >
                    Change Password
                  </Typography>
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Old Password "
                      type="password"
                      id="oldpassword"
                      sx={{ mt: 2 }}
                      {...register('oldpassword')}
                    />
                    {errors.oldpassword && (
                      <p style={{ color: 'red' }}>
                        {errors.oldpassword.message}
                      </p>
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="New Password "
                      type="password"
                      id="newpassword"
                      sx={{ mt: 2 }}
                      {...register('newpassword')}
                    />
                    {errors.newpassword && (
                      <p style={{ color: 'red' }}>
                        {' '}
                        {errors.newpassword.message}
                      </p>
                    )}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Confirm Password Name"
                      type="password"
                      id="confirmpassword"
                      sx={{ mt: 2 }}
                      {...register('confirmpassword')}
                    />
                    {errors.confirmpassword && (
                      <p style={{ color: 'red' }}>
                        {errors.confirmpassword.message}
                      </p>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mb: 1, mt: 3, fontWeight: '700' }}
                    >
                      Change Password
                    </Button>
                  </form>
                  {message && (
                    <p
                      style={{
                        color: message.includes('successfully')
                          ? 'green'
                          : 'red',
                      }}
                    >
                      {message}
                    </p>
                  )}
                </Box>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Profile;
