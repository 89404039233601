import {
  Applications,
  ApplicationType,
  AddApplication,
  ApplicationsOwn,
  ApplicationsOwns,
  LaunchApplication
} from '../services/applicationType';
import axiosInstance from '../config/apiconfig';

const ApplicationServices = {
  async applicationStore(payload: ApplicationType) {
    try {
      const response = await axiosInstance.post(`ApplicationTypes`, payload);
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async getApplicationTypes(id: number): Promise<Applications[]> {
    try {
      const response = await axiosInstance.get(`ApplicationTypes/${id}`);
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async addApplications(payload: AddApplication) {
    try {
      const userId = localStorage.getItem('LoginID');
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userId}/~AddApplication`,
        payload,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  //------------------------Dashboard Applications owns--------------------------------

  async applicationsOwn() {
    try {
      const userId = localStorage.getItem('LoginID');
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userId}/Applications_Own`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async getApplicationsOwnPayload(payload: ApplicationsOwns) {
    try {
      const response = await axiosInstance.get(
        `Domains/ID_2/Logins/ID_14/Applications_Own`,
        { params: payload },
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },

  async launchApplication(payload :LaunchApplication) {
    try {
      const userId = localStorage.getItem('LoginID');
      const response = await axiosInstance.post(
        `Domains/ID_2/Logins/ID_${userId}/Applications_/ID_${payload.applicationID}/~GetLaunchToken`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      throw error;
    }
  },


};

export default ApplicationServices;
