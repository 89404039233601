import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './loginRoutes';
import MainRoutes from './mainRoutes';
//import NotFoundRoute from './NotFoundRoute';
// ==============================|| ROUTING RENDER ||============================== //



export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes ]);
}