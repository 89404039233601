import axios, { AxiosInstance, AxiosResponse } from 'axios';

// Create an Axios instance with a base URL
const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://api.login.redochre.cloud/api/',
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,

      LoginToken: localStorage.getItem('LoginToken'),
      'Content-Type': 'text/plain',
    };

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can handle successful responses here
    return response;
  },
  (error: any) => {
    // You can handle errors here (e.g., display an error message)

    if (error.response && error.response.status === 401) {
      // Handle the specific error message
      // Clear local storage and redirect to the login page
      localStorage.clear();
      window.location.href = '/'; // Redirect to the login page
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
