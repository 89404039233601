import { lazy } from "react";
import React from "react";

// project import
import MinimalLayout from '../layout/minimalLayout';

// render - login
const AuthLogin = (lazy(() => import('../login/Login')));
const AuthSignup = (lazy(() => import('../Signup/signup')));
const ForgetPassword = (lazy(() => import('../login/forgotPassword')));
const NotFound = (lazy(() => import('../comman/notfound')));





// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: 'Signup',
      element: <AuthSignup />
    },
    {
      path: 'forgotPassword',
      element: <ForgetPassword />
    },
   
    {
      path: '*',
      element: <NotFound />
    },

  ]
};

export default LoginRoutes;
