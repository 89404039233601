import React from 'react';
import { useAuth } from './authProvider';


const WithAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const AuthenticatedComponent: React.FC<P> = (props) => {
    const {user} = useAuth();


    if (!user) {
      // If user is not logged in, redirect to the login page
       window.location.href = "/";
       return null;
    }

    // If user is logged in, render the wrapped component
    return <WrappedComponent {...props} />;
  };

  return AuthenticatedComponent;
};

export default WithAuth;
