
import { Box} from '@mui/material';

import Header from '../comman/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../comman/Footer';
import React from 'react';


const MainLayout:React.FC = () => {


  return (

    
      <Box>
      <Header/>
        <Outlet />
        {/* <Footer/> */}

       </Box>

    
  )
}

export default MainLayout