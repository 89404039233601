import React,{useState} from 'react'
import Loader from '../Loader';
 
function Launch() {
    const [isLoading, ] = useState(true);

  return (
    <div>
        <>
        {isLoading && <Loader />}
        {/* {setLoading(true)} */}
        </>
    </div>
  )
}

export default Launch